import { API_URL } from "@/constants/env";
import { MedicalRecords } from "@/models/interfaces/customer/customer.interface";
import axios from "axios";
import { prepareMedicalRecordData } from "./preparemedicalrecorddata.service";
import store from "@/store";

export const saveMedicalRecord = async (
  index: number,
  customerId: string,
  entity: any
  //entity: MedicalRecords
): Promise<void> => {
  const credential = { ...store.state.login.credential };

  const url = `${API_URL}/prontuario/salvaranamnese `;
  const fieldAnamnesis = entity.fieldsAnamnesis.filter(
    (item: any) => item.index == index
  )[0];
  const Campos = [
    { ...fieldAnamnesis, atendimento: store.state.customer.medicalRecords.id },
  ];

  const entitySave = {
    Cliente: credential.tenantId,
    Paciente: customerId,
    Profissional: credential.userId,
    Prontuario: [],
    Prescricao: "",
    Atestado: "",
    Relatorio: "",
    Campos,
    Atendimento: "N",
    Layout: 1,
  };

  console.log(entitySave);

  await axios.post(url, entitySave);

  /*   const invoiceProduct = prepareMedicalRecordData(entity);

  console.log(invoiceProduct); */

  //return invoiceProduct;
};

/*
Campos: [
  {
    Id: 13131,
    Nome: "Hora",
    Tipo: 2,
    Grupo: "",
    prontuario_id: 135,
    Valor: "bbb",
    Valor1: null,
    Valor2: null,
    Valor3: null,
    Valor4: null,
    Valor5: null,
    Valor6: null,
    Valor7: null,
    Valor8: null,
    Valor9: null,
    Valor10: null,
    Valor11: null,
    Valor12: null,
    Valor13: null,
    Valor14: null,
    Valor15: null,
    Valor16: null,
    Valor17: null,
    Valor18: null,
    Valor19: null,
    Valor20: null,
    Valor21: null,
    Valor22: null,
    Valor23: null,
    Valor24: null,
    Valor25: null,
    Valor26: null,
    Valor27: null,
    Valor28: null,
    Valor29: null,
    Valor30: null,
    Valor31: null,
    Valor32: null,
    Valor33: null,
    Valor34: null,
    Valor35: null,
    Valor36: null,
    Linha1: null,
    Linha2: null,
    Linha3: null,
    Linha4: null,
    Linha5: null,
    Linha6: null,
    Linha7: null,
    Linha8: null,
    Linha9: null,
    Linha10: null,
    Linha11: null,
    Linha12: null,
    Linha13: null,
    Linha14: null,
    Linha15: null,
    Linha16: null,
    Linha17: null,
    Linha18: null,
    Linha19: null,
    Linha20: null,
    Linha21: null,
    Linha22: null,
    Linha23: null,
    Linha24: null,
    Linha25: null,
    Linha26: null,
    Linha27: null,
    Linha28: null,
    Linha29: null,
    Linha30: null,
    Linha31: null,
    Linha32: null,
    Linha33: null,
    Linha34: null,
    Linha35: null,
    Linha36: null,
    Coluna: 0,
    Linha: 0,
    atendimento: 498709,
  },
],*/
