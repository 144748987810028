import { changeStatusActive } from "./changestatusactive.service";
import { getAll } from "./getall.service";
import { getAnamnesis } from "./getanamnesis.service";
import { getById } from "./getbyid.service";
import { getByNameForAutoComplete } from "./getbynameforautocomplete.service";
import { getMedicalCertificate } from "./medicalrecord/getmedicalcertificate.service";
import { getMedicalPrescription } from "./medicalrecord/getmedicalprescription.service";
import { getMedicalRecord } from "./medicalrecord/getmedicalrecord.service";
import { getMedicalRecords } from "./medicalrecord/getmedicalrecords.service";
import { getMedicalReport } from "./medicalrecord/getmedicalreport.service";
import { insert } from "./insert.service";
import { remove } from "./remove.service";
import { update } from "./update.service";
import { startMedicalCare } from "./medicalrecord/startmedicalcare.service";
import { finishMedicalCare } from "./medicalrecord/finishmedicalcare.service";
import { saveMedicalRecord } from "./medicalrecord/savemedicalrecord.service";

export const CustomerService = {
  getAll,
  getById,
  getByNameForAutoComplete,
  insert,
  remove,
  update,
  changeStatusActive,
  getMedicalRecords,
  getMedicalRecord,
  getAnamnesis,
  getMedicalCertificate,
  getMedicalPrescription,
  getMedicalReport,
  startMedicalCare,
  finishMedicalCare,
  saveMedicalRecord,
};
