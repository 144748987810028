import { API_URL } from "@/constants/env";

import store from "@/store";
import axios from "axios";

export const finishMedicalCare = async (): Promise<void> => {
  store.state.login.sharedVariables.medicalRecord.startMedicalRecord = 0;

  const id = store.state.customer.medicalRecords.id;
  console.log(store.state.customer.medicalRecords);

  const url = `${API_URL}/prontuario/finalizaratendimento?id=${id}`;

  axios.get(url);

  if (store.state.login.sharedVariables.medicalRecord.timerInterval) {
    clearInterval(
      store.state.login.sharedVariables.medicalRecord.timerInterval
    );
    store.state.login.sharedVariables.medicalRecord.timerInterval = null;
    store.state.login.sharedVariables.medicalRecord.elapsedTime = "";
  }

  window.removeEventListener(
    "beforeunload",
    store.state.customer.medicalRecords.session
  );
  console.log("remove", store.state.customer.medicalRecords.session);
};
