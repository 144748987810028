import { ActionTree } from "vuex";
import { RootState } from "@/store/type";
import {
  Anamnesis,
  Customer,
  CustomerState,
  MedicalCertificate,
  MedicalPrescription,
  MedicalRecord,
  MedicalRecords,
} from "@/models/interfaces/customer/customer.interface";
import { CustomerService as customer } from "@/services/customer/index";
import { constants } from "@/constants/customer";
import store from "..";

export const actions: ActionTree<CustomerState, RootState> = {
  async getAll({ commit }, { page, searchText, lastFilters }): Promise<void> {
    const res: Customer[] = await customer.getAll(
      page,
      searchText,
      lastFilters
    );
    commit(constants.SET_CUSTOMERS, res);
  },
  getById({ commit }, id): void {
    customer.getById(id).then((res: Customer) => {
      commit(constants.SET_CUSTOMER, res);
    });
  },
  getByNameForAutoComplete({ commit }, name): void {
    customer.getByNameForAutoComplete(name).then((res: Customer[]) => {
      commit(constants.SET_CUSTOMERS, res);
    });
  },

  async getMedicalRecords({ commit }, id): Promise<void> {
    const res: MedicalRecords = await customer.getMedicalRecords(id);
    commit(constants.SET_MEDICALRECORDS, res);
  },

  async getMedicalRecord({ commit }, { id, date }): Promise<void> {
    const res: MedicalRecord = await customer.getMedicalRecord(id, date);
    commit(constants.SET_MEDICALRECORD, res);
  },

  async startMedicalCare({ commit }, { id }): Promise<void> {
    customer.startMedicalCare(id);
    /*     commit(constants.SET_MEDICALRECORD, res); */
  },

  async finishMedicalCare({ commit }): Promise<void> {
    customer.finishMedicalCare();
  },

  async saveMedicalRecord(
    { commit },
    { index, customerId, entity }
  ): Promise<void> {
    customer.saveMedicalRecord(index, customerId, entity);
    /*     commit(constants.SET_MEDICALRECORD, res); */
  },

  async getAnamnesis(
    { commit },
    { id, customerId, description }
  ): Promise<void> {
    const res: Anamnesis = await customer.getAnamnesis(id, customerId);
    res.description = description;
    commit(constants.SET_ANAMNESIS, res);
  },

  async getMedicalCertificate(
    { commit },
    { id, customerId, description }
  ): Promise<void> {
    const res: MedicalCertificate = await customer.getMedicalCertificate(
      id,
      customerId
    );
    res.description = description;
    commit(constants.SET_MEDICALCERTIFICATE, res);
  },

  async getMedicalPrescription(
    { commit },
    { id, customerId, description }
  ): Promise<void> {
    const res: MedicalPrescription = await customer.getMedicalPrescription(
      id,
      customerId
    );
    res.description = description;
    commit(constants.SET_MEDICALPRESCRIPTION, res);
  },

  async remove({ commit }, ids): Promise<void> {
    await customer.remove(ids);

    /*  const page = 1;
    const searchText = "";
    const lastFilters: any[] = [];
    const res: Customer[] = await customer.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_CUSTOMERS, res); */
  },

  async changeStatusActive({ commit }, ids): Promise<void> {
    await customer.changeStatusActive(ids);

    const browsing = { ...store.state.login.browsing };

    const page = 1;
    const searchText = "";
    const lastFilters: any[] = browsing.lastFilters;
    const res: Customer[] = await customer.getAll(
      page,
      searchText,
      lastFilters
    );

    commit(constants.SET_CUSTOMERS, res);
  },

  async insert({ commit, state }): Promise<void> {
    const entity: any = await customer.insert(state.customer);

    state.customer.id = entity.Id;
    state.customer.idByCustomer = entity.IdCliente;
    state.customer.name = entity.Nome;
    state.customer.idCriptografado = entity.IdCriptografado;

    /* commit(constants.INSERT_CUSTOMER); */
  },

  async update({ commit, state }, id): Promise<void> {
    await customer.update(id, state.customer);
    /*   commit(constants.UPDATE_CUSTOMER, entity); */
  },
};
