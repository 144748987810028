import { API_BANKSLIP_URL, API_URL, BANKSLIP } from "@/constants/env";
import axios from "axios";
import { AccountReceivableService } from "../../financial/accountreceivable.services";
import { useDate } from "@/composables/date";
import { prepareData } from "../sale/preparedata.service";
import store from "@/store";

export const newBankslip = async (entity: any) => {
  const account: any = (
    await axios.get(`${API_URL}/conta/getId?id=${entity.ContaId}`)
  ).data;

  if (!account.Carteira) return;

  const customer: any = (
    await axios.get(`${API_URL}/paciente/getId?id=${entity.ClienteId}`)
  ).data;

  let city: any;
  //teste

  if (customer.DocumentoNf) {
    const cities: any = (
      await axios.get(`${API_URL}/cidade/todos?uf=${customer.UfNfId}`)
    ).data;

    city = cities.filter((item: any) => item.Id == customer.CidadeNfId)[0];
  } else {
    const cities: any = (
      await axios.get(`${API_URL}/cidade/todos?uf=${customer.UfId}`)
    ).data;

    city = cities.filter((item: any) => item.Id == customer.CidadeId)[0];
  }

  const nomeRazaoSocial = customer.DocumentoNf
    ? customer.NomeNf
    : customer.Nome;

  const numeroDocumento = customer.DocumentoNf
    ? customer.DocumentoNf?.replace(/[.-]/g, "")
    : customer.Cpf?.replace(/[.-]/g, "");
  const tipoPessoa = numeroDocumento.length > 11 ? "J" : "F";

  const tipoLogradouro = "Rua";
  const logradouro = customer.DocumentoNf
    ? customer.LogradouroNf
    : customer.Logradouro;
  const numero = customer.DocumentoNf ? customer.NumeroNf : customer.Numero;
  const complemento = customer.DocumentoNf
    ? customer.ComplementoNf
      ? customer.ComplementoNf
      : ""
    : customer.Complemento
    ? customer.Complemento
    : "";
  const bairro = customer.DocumentoNf ? customer.BairroNf : customer.Bairro;
  const cidade = city?.Nome;
  const uf = city?.UfSigla;
  const cep = customer.DocumentoNf
    ? customer.CepNf?.replace(/[.-]/g, "")
    : customer.Cep?.replace(/[.-]/g, "");

  if (!comeSale(entity)) entity.accountReceivable = [entity];

  const processBankslips = async () => {
    const isThereProcess =
      store.state.login.sharedVariables.processBankslips.some(
        item => item == entity.Id
      );
    debugger;
    console.log(
      isThereProcess,
      store.state.login.sharedVariables.processBankslips
    );
    if (isThereProcess) return;
    store.state.login.sharedVariables.processBankslips.push(entity.Id);
    for (const item of entity.accountReceivable) {
      if (item.Invoice) continue;

      const credentialsBankslip = BANKSLIP.filter(
        cred => cred.beneficiario == account.Carteira
      );

      if (
        (item.MeioPagamentoTipo == 6 && credentialsBankslip.length > 0) ||
        (item.newBankslip && credentialsBankslip.length > 0)
      ) {
        const credentialBankslip = credentialsBankslip[0];

        const bankslip = {
          clientId: credentialBankslip.clientId,
          urlCoperativa: credentialBankslip.urlCoperativa,
          codCoperativa: credentialBankslip.codCoperativa,
          idBeneficiario: account.Carteira,
          beneficiarioVariacaoCarteira: account.VariacaoCarteira,
          cooperativa: account.CedenteCodigo,
          seuNumero: item.Id,
          valor: item.Valor,
          vencimento: useDate("pt-BR").formatDateForInvoice(
            item.DataVencimentoFormatada
          ),
          protesto: {
            codigoParaProtesto: "DIAS_UTEIS",
            diasParaProtesto: 15,
          },
          pagador: {
            nomeRazaoSocial,
            nomeFantasia: nomeRazaoSocial,
            tipoPessoa,
            numeroDocumento,
            endereco: {
              tipoLogradouro,
              logradouro,
              numero,
              complemento,
              bairro,
              cidade,
              uf,
              cep,
            },
          },
        };

        const url = `${API_BANKSLIP_URL}/boletos/unicred`;

        try {
          entity.Observacao = null;
          item.Invoice = (await axios.post(url, bankslip)).data;
          entity.newInvoice = false;

          await AccountReceivableService.update(item.Id, item); // Certifique-se de usar `await` aqui
        } catch (ex: any) {
          console.log(ex);
          debugger;
          try {
            entity.Observacao = ex.response.data.body.reduce(
              (resultado: string, item: any) => {
                resultado += `${item.message};`;
                return resultado;
              },
              `${ex.response.data.message}: `
            );
          } catch (e: any) {
            entity.Observacao = ex.response.data.message;
          }
        }

        try {
          await axios.post(`${API_URL}/venda/Salvar`, prepareData(entity));
        } catch (error) {
          console.log("Erro ao salvar a venda:", error);
        }
      }
    }

    const index = store.state.login.sharedVariables.processBankslips.findIndex(
      item => item == entity.Id
    );
    store.state.login.sharedVariables.processBankslips.splice(index, 1);
  };

  // Executar a função principal
  await processBankslips();
};

const comeSale = (entity: any) => !!entity.accountReceivable;
