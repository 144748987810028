import { API_INVOICE_URL, X_API_KEY, API_URL } from "@/constants/env";
import axios from "axios";

export const correctionletter = async (
  id: number,
  chave: string,
  correction: string,
  status: number
) => {
  console.log(chave);
  if (!!chave && !(status == 5 || status == 7 || status == 4)) {
    try {
      const dataInvoice = {
        correcao: correction,
      };

      const invoice = (
        await axios.post(`${API_INVOICE_URL}/nfe/${chave}/cce`, dataInvoice, {
          headers: {
            "X-API-KEY": X_API_KEY,
          },
        })
      ).data;
      const url = `${API_URL}/nfe/updatestatuscartacorrecao?id=${id}&status=2`;
      await axios.get(`${url}`);
    } catch (ex: any) {
      console.log("Error: ", ex.response.data);

      const url = `${API_URL}/nfe/updatestatus?id=${id}&status=5`;
      await axios.get(`${url}`);
    }
  }
};
