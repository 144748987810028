import { useDate } from "@/composables/date";
import { API_URL } from "@/constants/env";
import store from "@/store";
import axios from "axios";

export const startMedicalCare = async (id: number): Promise<void> => {
  const credential = { ...store.state.login.credential };
  store.state.login.sharedVariables.medicalRecord.startMedicalRecord =
    Date.now();

  store.state.login.sharedVariables.medicalRecord.id = id;

  store.state.login.sharedVariables.medicalRecord.timerInterval = setInterval(
    () => {
      store.state.login.sharedVariables.medicalRecord.elapsedTime = elapsedTime(
        store.state.login.sharedVariables.medicalRecord.startMedicalRecord
      );
    },
    1000
  );
  const now = new Date();

  const date = useDate("pt-BR").format(now);

  const customerId = store.state.customer.medicalRecords.cliente.Id;

  const url = `${API_URL}/prontuario/IniciarAtendimento?data=${date}&profissionalId=${credential.userId}&clienteNoteProId=${credential.tenantId}&clienteId=${customerId}`;

  store.state.customer.medicalRecords.id = (await axios.get(url)).data;

  store.state.customer.medicalRecords.session = (event: BeforeUnloadEvent) => {
    event.preventDefault(); // Previne o fechamento automático
    //  event.returnValue = ""; // Requisito para mostrar o diálogo
  };
  window.addEventListener(
    "beforeunload",
    store.state.customer.medicalRecords.session
  );

  console.log("add");
};

const elapsedTime = (start: number) => {
  if (start) {
    const totalMilliseconds =
      Date.now() -
      store.state.login.sharedVariables.medicalRecord.startMedicalRecord;
    const totalSeconds = Math.floor(totalMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  }
  return "00:00:00";
};
